import VueRouter from "vue-router";
import Logon from "../pages/logon.vue"
import Main from "../pages/main.vue"

export default new VueRouter({
  routes:[
    {
      path:'*',
      component:Logon
    },
    {
      path:'/logon',
      component:Logon
    },
    {
      path:'/main',
      component:Main
    }
  ]
})

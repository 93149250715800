<template>
  <div class="logon-content">
    <div class="box">
      <h1 class="ac">系统登录</h1>
      <div class="input-box">
        <img src="../../public/image/yonghu.png" alt="">
        <input type="text" placeholder="请输入登录ID" v-model="username">
      </div>
      <div class="input-box">
        <img src="../../public/image/mima.png" alt="" >
        <input type="text" placeholder="请输入密码" v-model="password">
      </div>
      <!-- <div class="jizhu">
        <input type="checkbox">
        记住密码
      </div> -->
      <!-- <router-link class="btn ac" to="/main">
        登录
      </router-link> -->
      <a class="btn ac" @click="toMain">登录</a>
    </div>
  </div>
</template>

<script>
// import {post} from '../utils/request'
// import Qs from 'qs'
// import axios from 'axios'
import { post,get } from '@/utils/request'
export default {
  data(){
    return {
      username:'admin',
      password: '123'
    }
  },
  methods: {
    toMain(){

      post('/user/login',{
        "username": this.username,
        "password": this.password
      }).then(res => {
        console.log(res)
        if(res.data.code == 200){
          localStorage.setItem('token',res.data.data.token)
          this.$router.push({
            path:'/main'
          })
        }
      })

    }
  }
}
</script>

<style scoped>
  .logon-content{
    color: #fff;
    width: 100vw;
    height: 100vh;
    background: url(../../public/image/bg.png) no-repeat;
    background-size: 100% 100%;
    position: relative;
  }
  .box{
    background: url(../../public/image/border.png) no-repeat;
    background-size: 100% 100%;
    width: 32vw;
    height: 349.6px;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    padding: 20px;
  }

  .box h1{
    font-size: 26px;
  }

  .box .input-box{
    margin-top: 20px;
  }

  .box .input-box{
    height: 44.8px;
    line-height: 44.8px;
    opacity: 0.4;
    background: rgba(0,0,0,0.00);
    border: 2.4px solid #20a3f5;
    display: flex;
  }

  .box .input-box input{
    background: none;
    flex-grow: 1;
    border: none;
    line-height: 44.8px;
    outline: none;
    color: #fff;
  }

  .box .input-box img{
    width: 19px;
    height: 19px;
    margin: 10px 15px 0;
  }

  .box .jizhu{
    margin: 20px 0;
  }

  .box .btn{
    margin-top: 40px;
    display: block;
    height: 44.8px;
    line-height: 44.8px;
    background: #41acd2;
  }
</style>
<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import "../public/css/base.css"
import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'App',
  components: {
    HelloWorld
  }
}
</script>

<style>
  .f{
    display: flex;
  }


  .f-a{
    display: flex;
    justify-content: space-around;
  }

  .f-b{
    display: flex;
    justify-content: space-between;
  }
</style>

import axios from "axios"
const instance = axios.create({
    baseURL:'http://1.13.3.90:8080',
    timeout:5000
})


//全局请求拦截
instance.interceptors.request.use(
    function(config) {
        console.group('全局请求拦截')
        console.log(config)
        console.groupEnd()
        console.log(config)
        if(config.url == '/iot/hello'){
            console.log(localStorage.getItem('token'))
            config.headers['token'] = localStorage.getItem('token')
        }else if(config.url == '/iot/relieve') {
            config.headers['token'] = localStorage.getItem('token')
            config.headers['Content-Type'] = 'form-data'
        }
        // console.log(localStorage.getItem('token'))
        // config.headers['token'] = localStorage.getItem('token')
        
        return config
    },
    function(err){
        return Promise.reject(err)
    }
)


//全局请求拦截
instance.interceptors.response.use(
    function(response) {
        console.group('全局响应拦截')
        console.log(response)
        console.groupEnd()
        return response
    },
    function(err){
        return Promise.reject(err)
    }
)

export function get (url,params) {
    return instance.get(url,{
        params
    })
}

export function post (url,data){
    return instance.post(url,data)
}
<template>
    <div class="content">
        <div class="head f">
            <div class="equipment-name">
                设备名
            </div>
            <div class="name">
                用户名
            </div>
            
        </div>
        <div v-for="(item,index) in list" :key="index" class="body f">
            <div class="equipment-name" :title=" item.device ">{{item.device}}</div>
            <div class="name" :title=" item.nickname ">{{item.nickname}} </div>
            <div @click="relieve(item.device)" class="btn">
                解除绑定
            </div>
        </div>
    </div>
</template>

<script>
import {get, post} from '../utils/request'
export default {
    data(){
        return {
            list:[]
        }
    },
    methods:{
        relieve(device){
            get('/iot/relieve',{
                device
            }).then(res => {
                console.log(res)
                if(res.data.code == 200){
                    alert(res.data.msg)
                    this.getList()
                }else {
                    alert(res.data.msg)
                }
            })
        },
        getList(){
            post('/iot/hello').then(res => {
                console.log(res,'res')
                this.list = res.data.data
            })
        }
    },
    created(){
        this.getList()
    }
}
</script>

<style scoped>
    .f{
        display: flex;
    }
    .f-a{
        display: flex;
        justify-content: space-around;
    }
    .f-b{
        display: flex;
        justify-content: space-between;
    }
    .content{
        width: 500px;
        margin: 100px auto;
    }
    .head,.body{
        line-height: 66px;
        height: 66px;
        border: 1px solid;
    }
    .head>div,.body>div{
        border-right: 1px solid;
        text-align: center;
    }
    .head .name,.body .name{
        width: 120px;
        white-space:nowrap;
        overflow: hidden;
        text-overflow: ellipsis; 
    }

    .head .equipment-name,.body .equipment-name{
        width: 100px;
    }

    .body .btn{
        border-right: none;
        background: skyblue;
        flex-grow: 1;
    }
</style>